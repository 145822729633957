<ng-container *ngIf="playgroundPageDetails || academyPageDetails">
	@if (userType === permission.academy) {
	<app-head-section [sectionHeadTitle]="academyPageDetails.academy_name" [sectionCoverPicture]="
			academyPageDetails.cover_image ??
			'../../../../assets/images/image/default/Academies-cover.png'
		" [sectionSubHeadTitle]="academyPageDetails.branch_name" [setBackgroundToAcademy]="true" />
	} @if (userType === permission.playground){
	<section class="h-[65vh] w-screen headeImage" [ngStyle]="{
			background:
				'url(&quot;' +
				(playgroundPageDetails.cover_image[0]?.url ??
					'../../../../assets/images/image/default/Playground-cover.jpg') +
				'&quot;) no-repeat center center/cover'
		}">
		<div class="w-4/5 mx-auto h-full flex justify-between items-center">
			<div>
				<h1 class="text-fontColor font-black lg:text-6xl md:text-4xl text-2xl heading1">
					{{ playgroundPageDetails.play_ground_name }}
					<p class="text-fontColor lg:text-6xl text-xl mt-4">
						{{ playgroundPageDetails.branch_name }}
					</p>
				</h1>
			</div>

			<div class="flex flex-col justify-center items-center mb-16">
				<h1 class="text-textYellow text-8xl font-bold">
					{{ playgroundPageDetails.number_of_playgrounds || 0 }}
				</h1>
				<h1 class="text-white text-4xl font-semiBold">
					{{ "SPORT_ACADEMIES.PLAYGROUNDS" | translate }}
				</h1>
			</div>
		</div>
	</section>
	}

	<div class="flex justify-center items-center">
		<section class="lg:w-5/6 w-full lg:px-0 px-4">
			@if (userType === permission.academy) {
			<div class="grid grid-cols-3 gap-8 lg:gap-4 items-center text-center uppercase my-6">
				<div class="flex flex-col justify-center items-center">
					<h1 class="text-textYellow text-2xl lg:text-8xl text-4xl font-bold">
						+{{ academyPageDetails.number_of_players || 0 }}
					</h1>
					<h1 class="text-white text-sm lg:text-4xl text-sm font-semibold">
						{{ "header.menu_items.players" | translate }}
					</h1>
				</div>
				<div class="flex flex-col justify-center items-center">
					<h1 class="text-textYellow text-2xl lg:text-8xl text-4xl font-bold">
						{{ academyPageDetails.number_of_championships || 0 }}
					</h1>
					<h1 class="text-white text-sm lg:text-4xl text-sm font-semibold">
						{{ "SPORT_ACADEMIES.CHAMPIONSHIPS" | translate }}
					</h1>
				</div>
				<div class="flex flex-col justify-center items-center">
					<h1 class="text-textYellow text-2xl lg:text-8xl text-4xl font-bold">
						{{ academyPageDetails.number_of_playgrounds || 0 }}
					</h1>
					<h1 class="text-white text-sm lg:text-4xl text-sm font-semibold">
						{{ "SPORT_ACADEMIES.PLAYGROUNDS" | translate }}
					</h1>
				</div>
			</div>
			}

			<div class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 my-8">
				<!-- First Column -->
				<div class="flex justify-center items-start max-h-[420px]">
					<div class="flex flex-col justify-start items-start main-image overflow-hidden gap-4 h-full">
						@for (item of (academyPageDetails &&
						academyPageDetails.pictures.length > 1) ?
						academyPageDetails.pictures.slice(1,7) :
						(playgroundPageDetails &&
						playgroundPageDetails.pictures.length > 1 ) ?
						playgroundPageDetails.pictures?.slice(1,7) : []; track
						$index) {
						<img [src]="item?.url" (click)="imageClick($index + 1)"
							class="rounded-md w-20 h-20 object-cover cursor-pointer" alt="" />
						}
					</div>
					<div class="main-image overflow-hidden h-full w-full" [ngClass]="{
							'ms-4': (academyPageDetails &&
								academyPageDetails.pictures.length > 1) ||
								(playgroundPageDetails &&
									playgroundPageDetails.pictures.length > 1)
						}">
						@if ((academyPageDetails &&
						academyPageDetails.pictures.length > 0) ||
						(playgroundPageDetails &&
						playgroundPageDetails.pictures.length > 0)){
						<img [src]="
								academyPageDetails
									? academyPageDetails.pictures[0].url
									: playgroundPageDetails.pictures[0].url
							" (click)="imageClick(0)" class="h-full w-full object-cover cursor-pointer" alt="" />
						} @else {
						<img src="assets/images/image/default/Branch-default.png" class="h-full w-full object-cover"
							alt="" />
						}
					</div>
				</div>
			
				<!-- Second Column -->
				<div class="bg-semiLightGray rounded-md p-2 lg:p-4 flex flex-col justify-between shadow-md">
					<div class="flex justify-between items-center">
						<div>
							<h1 class="text-xl lg:text-3xl font-bold text-fontColor">
								{{
								userType === permission.playground
								? playgroundPageDetails.play_ground_name
								: academyPageDetails.academy_name
								}}
							</h1>
							<h2 class="text-sm lg:text-xl font-light text-fontColor">
								{{
								userType === permission.playground
								? playgroundPageDetails.branch_name
								: academyPageDetails.branch_name
								}}
							</h2>
						</div>
					</div>
			
					<p class="text-fontColor font-semiBold break-all text-sm lg:text-base mt-2 my-2 lg:mt-4 lg:my-4" [innerHTML]="getFormattedDescription()">
					</p>
			
					<div class="py-2 lg:py-4 border-y-2 border-textGreen">
						<div class="flex flex-wrap gap-3">
							@for (item of playgroundPageDetails ? playgroundPageDetails.sports :
							academyPageDetails.sport; track $index) {
							<p class="text-fontColor font-semibold text-xs lg:text-sm bg-lightGray px-2 lg:px-3 py-1 rounded-md">
								{{ item.name }}
							</p>
							}
						</div>
					</div>
			
					<div class="flex justify-between items-center pt-2 lg:pt-4">
						<div class="flex gap-3">
							<button (click)="showShare = true"
								class="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full border-2 text-fontColor/40 hover:text-textGreen border-fontColor/40 hover:border-textGreen transition-colors">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-4 h-4 lg:w-5 lg:h-5"
									fill="currentColor">
									<path
										d="M352 224H305.5c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8h-2.5c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144h80V34.7C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9L548.1 133.3c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2H384c-17.7 0-32-14.3-32-32V224zM80 96c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V384c0-17.7 14.3-32 32-32s32 14.3 32 32v48c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V112C0 67.8 35.8 32 80 32h48c17.7 0 32 14.3 32 32s-14.3 32-32 32H80z" />
								</svg>
							</button>
							<button type="button" (click)="toggleFavourite()"
								class="w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center rounded-full border-2 transition-colors hover:border-textGreen hover:text-textGreen"
								[ngClass]="{
									'text-red-500 border-red-500': isFavourite,
									'text-fontColor/40 border-fontColor/40': !isFavourite
								}">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 lg:w-5 lg:h-5"
									fill="currentColor">
									<path
										d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			
				<!-- Third Column: app-location-card -->
				<app-location-card 
					class="col-span-2 md:col-span-1" 
					[locationData]="playgroundPageDetails || academyPageDetails" 
				/>
			</div>
			

			<div class="lg:w-4/5 w-full lg:px-0 px-4 mb-10 mx-auto">
				<div class="w-full flex justify-center items-center">
					<ul class="tab-header flex flex-row justify-around items-center flex-wrap w-full">
						<li (click)="activeTab = 1" class="tab-item mt-3 md:mt-0">
							<a [class.active]="activeTab === 1" class="tab-link text-center whitespace-nowrap">
								@if (userType === permission.academy && hasTournaments) {
									{{ "SPORT_ACADEMIES.TOURNAMENTS" | translate }}
								} 
								@else {
									{{
									academyPageDetails
									? ("SPORT_ACADEMIES.academy_programs"
									| translate)
									: ("SPORT_ACADEMIES.playground_programs"
									| translate)
									}}
								}
							</a>
						</li>

						<!-- <li
							(click)="activeTab = 2"
							class="tab-item mt-3 md:mt-0"
						>
							<a
								[class.active]="activeTab === 2"
								class="tab-link text-center whitespace-nowrap"
							>
								{{ "SPORT_ACADEMIES.FACILITIES" | translate }}
							</a>
						</li> -->

						<li (click)="activeTab = 3" class="tab-item mt-3 md:mt-0">
							<a [class.active]="activeTab === 3" class="tab-link text-center whitespace-nowrap">
								{{ "SPORT_ACADEMIES.VIDEOS" | translate }}
							</a>
						</li>

						<li (click)="activeTab = 4" class="tab-item mt-3 md:mt-0">
							<a [class.active]="activeTab === 4" class="tab-link text-center whitespace-nowrap">
								@if (userType === permission.academy && hasTournaments) {
								{{ "SPORT_ACADEMIES.DRAW" | translate }}
								} 
								@else {
									{{ "SPORT_ACADEMIES.PHOTOS" | translate }}
								}
							</a>
						</li>

						<!-- <li
							(click)="activeTab = 5"
							class="tab-item mt-3 md:mt-0"
						>
							<a
								[class.active]="activeTab === 5"
								class="tab-link text-center whitespace-nowrap"
							>
								{{ "SPORT_ACADEMIES.REVIEWS" | translate }}
							</a>
						</li> -->
						<li (click)="activeTab = 6" class="tab-item mt-3 md:mt-0">
							<a [class.active]="activeTab === 6" class="tab-link text-center whitespace-nowrap">
								{{ "SPORT_ACADEMIES.NEWS" | translate }}
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="rounded-md w-full mb-16" [ngClass]="{ 'bg-semiLightGray': activeTab !== 1 }">
				<!-- Programs -->
				<ng-container *ngIf="activeTab === 1">
					<div class="grid md:grid-cols-3 gap-8 grid-cols-1 pt-4 mb-16">
						<!-- Programs List -->
						<div class="col-span-2">
							<div class="__list-container pt-5">
								<!-- If any programs or playgrounds exist -->
								@if ((academyPageDetails &&
								academyPageDetails.programs.length > 0) ||
								(playgroundPageDetails &&
								playgroundPageDetails.playgrounds?.length > 0)) {
								@for (
								item of academyPageDetails?.programs ?? playgroundPageDetails?.playgrounds;
								track $index
								) {
								@if (item.is_active) {
								<div
									class="grid lg:grid-cols-8 md:grid-cols-4 grid-cols-3 gap-2 mb-3 bg-semiLightGray rounded-md min-h-[180px]">

									<!-- Image Section -->
									<div class="main-image rounded-md lg:col-span-2 col-span-8 bg-contain bg-center">
										<img [src]="item?.media[0]?.original_url ?? 'assets/images/image/default/Branch-default.png'"
											class="max-h-[220px] min-h-[190px] object-cover w-full rounded-md"
											alt="Program Image" />
									</div>

									<!-- Details Section -->
									<div class="lg:col-span-4 col-span-8 p-1 lg:p-3 flex flex-col h-full">
										
										<!-- Row 1: Title & Sport (Least Height) -->
										<div class="flex justify-between items-start flex-shrink-0">
											<p class="w-full text-xl lg:text-2xl text-fontColor font-bold break-words">
												{{ item.name }}
											</p>
											<chip class="mx-2" colorHex="#f9c728">
												{{ item.sport.name }}
											</chip>
										</div>

										<!-- Row 2: Description (Takes Remaining Space) -->
										<p class="w-full mt-4 text-sm lg:text-base text-fontColor font-normal break-words flex-grow">
											{{ item.description }}
										</p>

										<!-- Row 3: Start Date (Sticks to Bottom) -->
										<div class="mt-4 flex justify-start flex-shrink-0">
											<chip class="font-semibold text-sm py-1 flex items-center" colorHex="#0569b1">
												<i class="pi pi-calendar mr-1 text-white"></i>
												<span class="text-white">
													{{ item.start_date | date:'dd/MM/yyyy' }}
												</span>
											</chip>
										</div>

									</div>

									<!-- Pricing & Booking Section -->
									<div
										class="col-span-8 lg:col-span-2 grid grid-cols-3 lg:flex lg:flex-col lg:justify-between items-center p-1 lg:p-3 gap-3 text-center">

										<!-- Price & Discounted Price (Same Column in Small Screens) -->
										<div class="flex flex-col items-center">
											<p class="text-4xl text-textYellow font-bold">
												{{ item.price - (item.price * (item.discount / 100)) }}
												<span class="text-lg lg:text-2xl">
													{{ "SPORT_ACADEMIES.Currency_eg" | translate }}
												</span>
											</p>

											@if (item.discount > 0) {
											<p class="diagonal-strike text-textYellow">
												{{ item.price }}
											</p>
											}
										</div>

										<!-- Availability Status -->
										<div class="flex items-center justify-center">
											<ng-container *ngIf="userType === permission.academy">
												@if (item.max_number_of_players > item.reservations_count) {
												<chip class="flex items-center px-3 py-1"
													[colorHex]="item.is_booked ? '#d6303b' : '#69bb16'">
													<span *ngIf="!item.is_booked">
														<i class="pi pi-check-circle"></i>
														{{ "booking.available" | translate }}
													</span>
													<span *ngIf="item.is_booked">
														<i class="pi pi-wallet mr-2"></i>
														{{ "booking.reserved" | translate }}
													</span>
													<small class="block">
														{{ "booking.remainingSlots" | translate }} :
														{{ item.max_number_of_players - item.reservations_count }}
													</small>
												</chip>
												} @else {
												<chip class="flex items-center px-3 py-1" colorHex="#d6303b">
													<span>
														<i class="pi pi-users pr-2"></i>
														{{ "booking.fullyBooked" | translate }}
													</span>
												</chip>
												}
											</ng-container>
										</div>
										
										<!-- Reserve Button -->
										<div class="flex justify-center">
											<button [disabled]="item.max_number_of_players <= item.reservations_count"
												(click)="navigateToSchedule(item.id)"
												class="flex justify-center items-center w-full px-10 h-10 text-base text-fontColor rounded-lg shadow-md"
												[ngClass]="item.max_number_of_players <= item.reservations_count 
                ? 'bg-gray-500 text-gray-600 cursor-not-allowed' 
                : 'bg-lightBlue hover:bg-darkBlue hover:border hover:border-lightBlue hover:text-lightBlue'">
												{{ "SPORT_ACADEMIES.Reserve" | translate }}
											</button>
										</div>

									</div>


								</div>
								}
								}
								} @else {
								<!-- If no programs found -->
								<div class="flex flex-col justify-center items-center rounded-md bg-semiDarkBlue">
									<ng-lottie [options]="options"
										class="w-[50%] sm:w-[40%] md:w-[30%] lg:w-[25%] xl:w-[20%]"></ng-lottie>
									<!-- <h3 class="text-base mt-0 p-1 text-center text-fontColor font-bold opacity-70">
											{{ "no_programs_found" | translate }}
										</h3> -->
								</div>
								}
							</div>
						</div>


						<!-- Ad -->
						<div>
							<div class="w-3/5 mx-auto flex flex-col items-center mt-4 md:grid hidden">
								<e3lan-card [deviceType]="deviceType()" [data]="section1"
									[isLoading]="isShowShimmerForE3lan" />
							</div>
						</div>
					</div>

				</ng-container>

				<!-- Attachments -->
				<ng-container *ngIf="activeTab === 2">
					<div class="about w-full m-auto">
						<p-carousel [value]="slidesStore" [numVisible]="1" [numScroll]="1" [circular]="true"
							class="relative" [autoplayInterval]="8000" [responsiveOptions]="
								playersCarouselResponsiveOptions
							">
							<ng-template let-slider pTemplate="item">
								<div class="slider p-4 rounded-lg flex justify-between items-center">
									<div class="photo w-6/12 rounded-lg overflow-hidden">
										<img src="{{ slider.src }}" [alt]="slider.title"
											class="relative h-full w-full object-cover" />
									</div>
									<div class="content w-6/12 p-4 flex flex-col justify-center items-center">
										<div class="text-xl text-fontColor font-bold">
											{{ "testData.XSLorem" | translate }}
										</div>
										<div class="desc text-fontColor font-normal text-sm mt-4">
											{{ "testData.Lorem" | translate }}
										</div>
									</div>
								</div>
							</ng-template>
						</p-carousel>
					</div>
				</ng-container>

				<!-- Videos -->
				<ng-container *ngIf="activeTab === 3">
					<div *ngIf="galleryVideos.length > 0" class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
						@for (item of galleryVideos; track $index) {
						<app-cart-video [isShowSelected]="false" [data]="item"></app-cart-video>
						}
					</div>
					<div *ngIf="galleryVideos.length === 0" class="flex justify-center">
						<ng-lottie [options]="options"
							class="w-[50%] sm:w-[40%] md:w-[30%] lg:w-[25%] xl:w-[20%]"></ng-lottie>
					</div>
				</ng-container>

				<!-- Photos -->
				<ng-container *ngIf="activeTab === 4">
					<div *ngIf="galleryImages.length > 0"
						class="flex flex-row flex-wrap justify-center lg:justify-start gap-4">
						<!-- @for (item of [].constructor(10); track $index) { -->
						<!-- <app-cart-player [isShowSelected]="false" /> -->
						<!-- <app-photos [galleryImages]="galleryImages"></app-photos> -->
						<!-- } -->
						<!-- comment ahmed omar -->
						@for (item of galleryImages; track $index) {
						<cart-slider [isShowSelected]="false" [image]="item.url" [index]="$index"
							[totalNumberOfItems]="galleryImages.length" [image]="item.url" [currentItem]="currentItem"
							[currentImage]="currentImage" (nextClicked)="prevImage($event)"
							(prevClicked)="nextImage($event)"></cart-slider>
						}
					</div>
					<div *ngIf="galleryImages.length === 0" class="flex justify-center">
						<ng-lottie [options]="options" class="w-[50%] sm:w-[40%] md:w-[30%] lg:w-[25%] xl:w-[20%]">
						</ng-lottie>
					</div>
				</ng-container>

				<!-- Reviews -->
				<ng-container *ngIf="activeTab === 5">
					<div class="bg-darkBlue"></div>
					<div
						class="bg-darkBlue flex flex-row justify-center md:justify-between items-center flex-wrap pt-8">
						<p class="text-2xl font-light text-lightGray">
							{{ "testData.pagination" | translate }}
						</p>
						<div class="flex flex-row justify-start items-center md:mt-0 mt-4">
							<!-- <div class="w-10 h-10 rounded-full bg-lightGreen  flex flex-row justify-center text-fontColor ms-2 items-center hover:bg-lightGreen">{{'\<'}}</div> -->
							<div
								class="w-10 h-10 rounded-full bg-lightGray flex flex-row justify-center text-fontColor ms-2 items-center hover:bg-lightGreen">
								1
							</div>
							<div
								class="w-10 h-10 rounded-full bg-lightGray flex flex-row justify-center text-fontColor ms-2 items-center hover:bg-lightGreen">
								2
							</div>
							<div
								class="w-10 h-10 rounded-full bg-lightGray flex flex-row justify-center text-fontColor ms-2 items-center hover:bg-lightGreen">
								3
							</div>
							<!-- <div class="w-10 h-10 rounded-full  bg-lightGray flex flex-row justify-center text-fontColor ms-2 items-center hover:bg-lightGreen">{{'\>'}}</div> -->
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="activeTab === 6">
					<div *ngIf="galleryNews.length > 0">
						<app-news [Data]="galleryNews"></app-news>
					</div>
					<div *ngIf="galleryNews.length === 0" class="flex justify-center items-center">
						<ng-lottie [options]="options" class="w-[50%] sm:w-[40%] md:w-[30%] lg:w-[25%] xl:w-[20%]">
						</ng-lottie>
					</div>
				</ng-container>
			</div>
		</section>
	</div>

	<p-galleria [value]="
			academyPageDetails
				? academyPageDetails.pictures
				: playgroundPageDetails.pictures
		" [(visible)]="displayLightbox" [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions"
		[containerStyle]="{ 'max-width': '850px' }" [numVisible]="7" [circular]="true" [fullScreen]="true"
		[showItemNavigators]="true" [showThumbnails]="false">
		<ng-template pTemplate="item" let-item>
			<img [src]="
					item.url ?? 'assets/images/image/default/Branch-default.png'
				" style="width: 100%; display: block; max-height: 100vh" />
		</ng-template>
	</p-galleria>

	<popUp-messaging-dialog [visible]="dilogIsOpen" (onCancel)="dilogIsOpen = false"
		(onConfirm)="closeDialog($event)"></popUp-messaging-dialog>
</ng-container>

<app-share-modal [visible]="showShare" [urlToShare]="currentWebsiteUrl + '/' + urlToShare"
	(OnHide)="showShare = $event"></app-share-modal>