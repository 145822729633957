import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnInit, Output, PLATFORM_ID, signal, WritableSignal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Plans } from "@sportyano/core/models/subscription/Plan";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { SubscriptionsService } from "@sportyano/core/services/subscriptions/subscriptions.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { PAYMENT_METHOD } from "@sportyano/main-site/booking/booking/models/const/booking.const";
import { IconComponent } from "@sportyano/shared/components/icon/icon.component";
import { ICONS } from "@sportyano/shared/components/icon/models/icon";
import { ConvertdecimaltonumberPipe } from "@sportyano/shared/pipes/convert-to-number/convertdecimaltonumber.pipe";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TooltipModule } from "primeng/tooltip";



@Component({
	selector: "tab-subscription",
	templateUrl: "./subscription.component.html",
	styleUrl: "./subscription.component.scss",
	standalone: true,
	imports: [
		TranslateModule,
		CommonModule,
		FormsModule,
		ConvertdecimaltonumberPipe,
		IconComponent,
		ConfirmDialogModule,
		TooltipModule,
	],
})
export class SubscriptionComponent implements OnInit {


	// injects
	private _subscriptionsService: SubscriptionsService = inject(SubscriptionsService);
	private _toasterMessagesService: ToasterMessagesService = inject(ToasterMessagesService);
	private _confirmationService: ConfirmationService = inject(ConfirmationService);
	private _translateService: TranslateService = inject(TranslateService);
	private _router:Router = inject(Router);
	private _authService:AuthService = inject(AuthService)
	private platform = inject(PLATFORM_ID);

	// private
	private userType : string | null = this._authService.getUserType();

	// Replace Cancel Button With Continue In Registeration
	@Input() hiddenInAccount: boolean = true;
	// TO SHARE THE SELECTED PLAN BETWEEN OTHER COMPONENTS
	@Output() emitSelectedPlan: EventEmitter<Plans> = new EventEmitter<Plans>();
	@Input({ required: true }) selectedSubscription: number = 1;
	@Input({ required: true }) selectedPlan: number;
	@Input({ required: true }) subscriptionPlans!: Plans[];
	public PAYMENT_METHOD = PAYMENT_METHOD;
	public showMoreMap: WritableSignal<boolean> = signal<boolean>(false)
	public ICONS = ICONS
	// signals
	public isAccountRoute :WritableSignal<boolean> = signal<boolean>(false)
	public subscribedPlan:WritableSignal<number> = signal<number>(1);
	ngOnInit() {
		let planId = this._authService.getUserData().subscription?.plan?.id || this._authService.getUserData().subscription?.id
		this.subscribedPlan.set(planId)
		this.isAccountRoute.set(this._router.url.includes('account'))
	}


	toggleShowMore(): void {
		this.showMoreMap.set(!this.showMoreMap())
	}
	subscribe(plan: Plans, subscriptionId: any): void {
		// this.subscriptionPlans.forEach((plan) => {
		// 	plan.subscribed = plan.id == subscriptionId;
		// });
		if (isPlatformBrowser(this.platform)) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
		console.log(plan,"plan");
		this.emitSelectedPlan.emit(plan);
	}

	public subscribeForFreePlan(plan:Plans,plan_id: number) {
		this._subscriptionsService.subscribeToFreePlan(this.userType!, plan_id, PAYMENT_METHOD.cash).subscribe({
			next: (res: any) => {
				// update local storage with free plan
				this._updateLocalStorageWithFreePlan(plan)
				// update ui with new plan id
				this.selectedPlan =this._authService.getUserData().subscription?.plan?.id || this._authService.getUserData().subscription?.id
				this.subscribedPlan.set(this.selectedPlan)
				// dialog
				this._openDialog(res.message);
			},
			error: (err) => {
				this._toasterMessagesService.showError(err.message)
			}
		})
	}
  private _updateLocalStorageWithFreePlan(plan:Plans){
	let userData = this._authService.getUserData();
	userData.subscription =plan
	localStorage.setItem('userData', JSON.stringify(userData))
  }
	private _openDialog(headerMessage: string): void {
		this._confirmationService.confirm({
			header: this._translateService.instant("subscriptions.congratulations"),
			message: this._translateService.instant(
				headerMessage
			) as string,
			acceptLabel: undefined, // Dynamic accept button label
			rejectLabel: this._translateService.instant("confirmation.cancel"), // Dynamic reject button label
			icon: this.ICONS.celebration,
			accept: () => {
				// this._router.navigate(
				// 	["/main"],
				// );
			},
			reject: () => { },
		});
	}

}


