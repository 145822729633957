import { CommonModule } from "@angular/common";
import { HttpResponse } from "@angular/common/http";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	SimpleChange,
	ViewChild,
	WritableSignal,
	inject,
	signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { VideosComponent } from "@sportyano/account/components/news-and-media/videos/videos.component";
import { News, Photos, Videos } from "@sportyano/core/models/account/news";
import {
	FileType,
	IUploadedFile,
} from "@sportyano/core/models/file-upload/file-upload.model";
import { permission } from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { MediaService } from "@sportyano/core/services/media/media.service";
import { NewsService } from "@sportyano/core/services/playerProfile/news.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { ACCEPTED_IMAGE_EXTENSIONS } from "@sportyano/shared/models/const/constant";
import { FileSizePipe } from "@sportyano/shared/pipes/file-size.pipe";
import { NgxSpinnerService } from "ngx-spinner";
import { ProgressBarModule } from "primeng/progressbar";
import { finalize, takeUntil, concatMap, tap, last, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
	selector: "add-post-pop-up",
	templateUrl: "./add-post-pop-up.component.html",
	styleUrls: ["./add-post-pop-up.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ProgressBarModule,
		FileSizePipe
	]
})
export class AddPostPopUpComponent {
	dialogMode: 'add' | 'edit' | null = null; // or use an enum for better clarity

	//flags
	public isSubmitting: boolean = false;
	private _toaster = inject(ToasterMessagesService);
	private _translateService = inject(TranslateService);
	private _mediaService = inject(MediaService);
	// INPUTS
	@Input() visible: boolean = false;
	@Input() isEdit: boolean = false;
	@Input() isNews: boolean = false;
	@Input() isPhotos: boolean = false;
	@Input() isVideo: boolean = false;
	@Input() news: News | null = null;
	@Input() videos: Videos = {} as Videos;
	@Input() photos: Photos = {} as Photos;
	@Input() fileType: FileType;
	videoTitle: string = '';
	videoDescription: string = '';
	// OUTPUTS
	@Output() onConfirm: EventEmitter<unknown> = new EventEmitter<unknown>();
	@Output() onVisible: EventEmitter<unknown> = new EventEmitter<unknown>();
	@Output() canAddVideo:EventEmitter<unknown> = new EventEmitter<unknown>();
	@Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild("fileInput", { static: false }) fileInput!: ElementRef;
	@ViewChild(VideosComponent) videosComponent!: VideosComponent;
	file: File | null;
	selectedImage: any;
	userId: any;
	routerName: string;
	videoSrc: string = "";
	// Public
	public uploadedFile: WritableSignal<IUploadedFile | null> =
		signal<IUploadedFile | null>(null);
	public uploadedProgress: WritableSignal<number> = signal<number>(0);
	public ACCEPTED_IMAGE_EXTENSIONS = ACCEPTED_IMAGE_EXTENSIONS;
	public isUploading: boolean = false;
	private _destroy$ = new Subject<boolean>();
	constructor(
		private _newsServices: NewsService,
		private authService: AuthService,
		private spinner: NgxSpinnerService
	) {
		// console.log(this.videos.video);

		this.userId = this.authService.getAuthenticationStateSync()
			? this.authService.getUserData().id
			: null;
		let typeOfUser = this.authService.getAuthenticationStateSync()
			? this.authService.getUserType()
			: null;

		if (typeOfUser == permission.player) this.routerName = "players";
		if (typeOfUser == permission.academy) this.routerName = "academies";
		if (typeOfUser == permission.playground)
			this.routerName = "playgrounds";


	}

	ngOnChanges(changes: SimpleChange) {
		// console.log(changes);
		if (this.isEdit && this.videos) {

			// console.log(this.videos);
		} else if (!this.isEdit) {
			this.resetFields(); // Reset fields when not in edit mode
		}
	}
	private resetFields() {
		this.videoTitle = '';
		this.videoDescription = '';
		this.file = null;
		this.selectedImage = null;
		this.videoSrc = '';
	}
	public checkInputValidity(
		control: any,
		validations: { type: string; value: any }[]
	): void {
		const value = control.value;
		const errors: { [key: string]: boolean } = {};

		validations.forEach((validation) => {
			switch (validation.type) {
				case "required":
					if (!value || value === "0") {
						errors["required"] = true;
					}
					break;
				case "minlength":
					if (value.length < validation.value) {
						errors["minlength"] = true;
					}
					break;
				case "maxlength":
					if (value.length >= validation.value) {
						errors["maxlength"] = true;
					}
					break;
			}
		});

		control.control.setErrors(
			Object.keys(errors).length > 0 ? errors : null
		);
	}

	selectFile() {
		this.fileInput.nativeElement.value = null;
		this.fileInput.nativeElement.click();
	}
	resetPreview() {
		this.selectedImage = null;
		this.file = null;
		this.videoSrc = "";  // Clear the video source
		this.uploadedProgress.set(0)
		this.videos.title = ""; // Reset video title
		this.videos.description = ""; // Reset video description
		// this.resetInputValue();
	}
	handleFile(e: any) {
		this.file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (e: any) => {
			this.selectedImage = e.target.result;
		};
		reader.readAsDataURL(e.target.files[0]);
		if (this.file) {
			this.previewVideo(this.file);
		}
	}
	private previewVideo(file: File) {
		const reader = new FileReader();
		reader.onload = (e: any) => {
			this.videoSrc = e.target.result;
		};
		reader.readAsDataURL(file);
	}

	addPost() {
		if (this.isUploading) return; // flag to avoid uploading
		this.isUploading = true;
		const formData = new FormData();
		if (this.file) {
			formData.append("file", this.file, this.file.name);
		} else {
			this._toaster.showError(
				this._translateService.instant(
					"videos_images_news_Messages.videoDescription"
				)
			);
			this.isUploading = false;
			return;
		}
		this.videos.title = this.videoTitle
		this.videos.description = this.videoDescription
		if (this.isVideo && (this.videos.title.length < 3 || this.videos.description.length < 3)) {
			console.log('this.videos.title ', this.videos.title)
			this._toaster.showError(
				this._translateService.instant(
					"videos_images_news_Messages.videoDescription"
				)
			);
			this.isUploading = false;
			return;
		}
		else if (this.isPhotos && this.userId) {
			this._newsServices
				.addPhotos(
					this.routerName,
					this.photos,
					this.userId,
					formData
				)
				.subscribe({
					next: (res) => {
						this._toaster.showSuccess(
							this._translateService.instant(
								"videos_images_news_Messages.photoAdded"
							)
						);
						this.resetPreview();
						this.onConfirm.emit();
						this.isUploading = false;
					},
					error: () => {
						this.isUploading = false;
					}
				});
		}
		// else if (this.isVideo && this.playerId) {
		// this._upoloadVideo(this.file, formData);
		// }
		else if (this.isVideo && this.userId) {
			this._uploadVideo()
		}
	}
	// chunks and videos
	private _uploadVideo() {


		if (!this.file) {
			this._toaster.showError(
				this._translateService.instant("videos_images_news_Messages.no_file")
			);
			return;
		}

		if (this.file.size > environment.videoConfig.max_size_bytes) {
			this._toaster.showError(
				this._translateService.instant("videos_images_news_Messages.file_size")
			);
			this.isUploading = false;
			return;
		}

		if (!this._isValidVideoFile(this.file)) {
			this._toaster.showError(
				this._translateService.instant("videos_images_news_Messages.file_type")
			);
			this.isUploading = false;
			return;
		}

		this.uploadedProgress.set(2);
		let uploadedChunks = 0;
		const totalChunks = Math.ceil(this.file.size / environment.videoConfig.chunk_size);

		this._newsServices.uploadVideoInChunks(this.file).pipe(
			tap((response: any) => {
				if (response) {
					uploadedChunks++;
					const percentDone = Math.round((uploadedChunks / totalChunks) * 100);
					this.uploadedProgress.set(percentDone);
				}
				
			}),
			takeUntil(this._destroy$),
			last(),
			concatMap((response: any) => {
				if (!response) {
					throw new Error("Video upload failed");
				}

				return this._saveVideoDetails(response);
			}),
			finalize(() => {
				this.spinner.hide();
				this.resetPreview();
				this.isUploading = false;
				this.onCancelUploadDialog();
			})
		).subscribe({
			next: (res:any) => {
				this._toaster.showSuccess(
					'Video uploaded successfully'
				);
				this.canAddVideo.emit(res)
				this.resetPreview();
				this.onConfirm.emit();
			},
			error: () => {
			
			},
		});
	}

	// method to save video details to all accounts 
	private _saveVideoDetails(response: any) {
		const videoDetails = {
			title: this.videos.title,
			description: this.videos.description,
			video_url: response.video_url,
			video_name: response.video_name,
		};

		return this._newsServices.addVideoDetails(this.routerName, this.userId, videoDetails);
	}

	private _isValidVideoFile(file: File): boolean {
		const validExtensions = [
			'mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'vob', 'ogv', 'ogg', 'drc',
			'gif', 'gifv', 'mng', 'qt', 'mpg', 'mpeg', 'm4v', '3gp', '3g2', 'nsv', 'f4v',
			'f4p', 'f4a', 'f4b'
		];
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		return fileExtension ? validExtensions.includes(fileExtension) : false;
	}
	private _deleteNewsById(id: number) {
		this._newsServices.deleteNews(id).subscribe({
			next: () => {
				this._toaster.showSuccess("Post Deleted Successfully");
				this.resetPreview();
			}
		})
	}

	public titleChange(value: string) {
		this.videoTitle = value;

	}
	public descriptionChange(value: string) {
		this.videoDescription = value
	}


	editItem() {
		const isTitleValid = !this.videoTitle || this.videoTitle.length >= 3; // Title is either empty or valid
		const isDescriptionValid = !this.videoDescription || this.videoDescription.length >= 3; // Description is either empty or valid

		if (!isTitleValid || !isDescriptionValid) {
			this._toaster.showError(
				this._translateService.instant(
					"videos_images_news_Messages.videos_title_and_description_validation"
				)
			);
			return;
		}

		this.isSubmitting = true;
		// Only update title if a new title is provided and it's different from the old one
		if (this.videoTitle && this.videoTitle !== this.videos.title) {
			this.videos.title = this.videoTitle;
		}

		// Update description only if the user has provided a new description and it's different from the existing one
		if (this.videoDescription && this.videoDescription !== this.videos.description) {
			this.videos.description = this.videoDescription;
		}
		this.onConfirm.emit({
			...this.videos
		})
		if (this.isVideo) {
			// this._mediaService.updateVideoTitleAndDescription(this.playerId,this.videos.id, this.videos).subscribe({
			// 	next: () => {
			// this._deleteVideoById(videoId);
			this.onVisible.emit()
			// 	}
			// })
		}

		if (this.file) {
			console.log('file');

			console.log("file", this.file);
			const formData = new FormData();
			formData.append("file", this.file, this.file.name);
			if (this.isNews) {
				this._newsServices.updateNewsWithImages(this.news, this.news!.id, formData).subscribe({
					next: () => {
						this._toaster.showSuccess(
							this._translateService.instant(
								"edit_news"
							)
						);
						this.resetPreview();
						this.onConfirm.emit();
					}
				})
			}
		}
		if (!this.file) {
			console.log('no file');

			if (this.isNews) {
				// delete this.news.image_url;
				let content_ar = this.news?.content_ar;
				let content_en = this.news?.content_en;
				// let image_url =this.news.image_url;

				this._newsServices.updateNews({ content_ar, content_en }, this.news!.id).subscribe({
					next: (res) => {
						// this._toaster.showSuccess("Post Updated Successfully");
						this._toaster.showSuccess(
							this._translateService.instant(
								"edit_news"
							)
						);
						this.resetPreview();
						this.onConfirm.emit();
					},
				});
			}
		}
	}
	editItemPending() {
		const isTitleValid = !this.videoTitle || this.videoTitle.length >= 3; // Title is either empty or valid
		const isDescriptionValid = !this.videoDescription || this.videoDescription.length >= 3; // Description is either empty or valid

		if (!isTitleValid || !isDescriptionValid) {
			this._toaster.showError(
				this._translateService.instant(
					"videos_images_news_Messages.videos_title_and_description_validation"
				)
			);
			return;
		}

		this.isSubmitting = true;
		// Only update title if a new title is provided and it's different from the old one
		if (this.videoTitle && this.videoTitle !== this.videos.title) {
			this.videos.title = this.videoTitle;
		}

		// Update description only if the user has provided a new description and it's different from the existing one
		if (this.videoDescription && this.videoDescription !== this.videos.description) {
			this.videos.description = this.videoDescription;
		}
		this.onConfirm.emit({
			...this.videos
		})
		if (this.isVideo) {
			// this._mediaService.updateVideoTitleAndDescriptionPending(this.videos.id, this.videos).subscribe({
			// 	next: () => {
			this.onVisible.emit()
			// this._deleteVideoById(videoId);
			// 	}
			// })
		}

	}

	editItemPendingAndApprove() {
		if (this.videos.status === 1) {
			this.editItem()
		}
		else if (this.videos.status === 2) {
			this.editItemPending()
		}
	}
	resetInputValue() {
		if (
			this.fileInput &&
			this.fileInput.nativeElement &&
			this.fileInput.nativeElement.value !== undefined
		) {
			this.fileInput.nativeElement.value = "";
		}
	}

	onCancelUploadDialog() {
		this.fileInput.nativeElement.value = null;
		this.selectedImage = null;
		this.file = null;
		this.onCancel.emit();
		this.isSubmitting = false;
		this._destroy$.next(true);
	  
}



}
