<section class="relative h-[20vh] md:h-auto bg-no-repeat bg-cover bg-center w-full section-header">
	<!-- Background overlay for better readability -->
	<div class="absolute inset-0 bg-black/50 z-0"></div>

	<img [src]="sectionCoverPicture" alt="" class="w-full h-full object-cover" />

	<div class="absolute inset-0 z-10 w-4/5 max-w-screen-lg mx-auto flex items-center justify-center text-center">
		<h2 class="header-title text-fontColor uppercase font-black lg:text-6xl md:text-4xl text-2xl"
			[ngClass]="setBackgroundToAcademy ? 'header-background-academy' : 'no-background'">
			{{ sectionHeadTitle }}

			<span *ngIf="sectionSubHeadTitle && sectionSubHeadTitle.length > 0">
				<p class="text-fontColor lg:text-2xl text-xl mt-2 md:mt-4"
					[ngClass]="setBackgroundToAcademy ? 'header-background-academy' : 'no-background'">
					{{ sectionSubHeadTitle }}
				</p>
			</span>
		</h2>
	</div>
</section>