<cart
   appSpecialCartDirective
   [playerData]="playerData"
   
	[image]="
		playerData?.avatars?.length
			? playerData?.avatars[playerData?.avatars?.length - 1]?.url
			: '/assets/images/image/default/Player-default.jpg'
	"
	[title]="playerData?.first_name + ' ' + playerData?.last_name[0] + '.'"
	[axis]="'vertical'"
	(click)="openPlayer()"
	class="relative cart-player block rounded-2xl"
	[type]="'player'"
>
	<div
		imageOverlay
		StopClickPropagation
		class="cart-action absolute rounded-2xl top-0 right-0"
	>
		<div class="content p-2">
			<div class="head flex justify-between items-center relative">
				<div class="flex gap-2" StopClickPropagation>
					<img
						(click)="showShare = true"
						class="size-4 cursor-pointer"
						src="assets/icons/Group 41882.svg"
						alt=""
					/>
					<button
						StopClickPropagation
						[disabled]="
							playerData?.is_interest_shown ||
							playerState()?.is_interest_shown
						"
						(click)="likePlayer()"
					>
						<img
							class="size-4 cursor-pointer"
							[src]="
								playerData?.is_interest_shown ||
								playerState()?.is_interest_shown
									? 'assets/icons/Icon ionic-ios-star.svg'
									: 'assets/icons/Icon-star.svg'
							"
							alt=""
						/>
					</button>
				</div>
			</div>
		</div>
	</div>
	<span postContent>
		<div
			dir="auto"
			class="player overflow-hidden w-full h-fit max-h-[400px]"
			[class.show]="showDetails"
		>
			<div *ngIf="playerData?.age" class="flex justify-between">
				<span class="mt-1 sub-title text-white text-sm">
					{{ playerData?.age }}
					{{ "cart_player.age" | translate }}
				</span>
			</div>

			<div class="body mt-1">
				<div class="d-flex">
					<chip
						colorHex="#69bb16"
						[label]="
							playerData?.sport_name ??
							(sportsList$ | async)?.get(playerData)?.name
						"
					></chip>

					<chip
						*ngIf="playerData?.sport_option_position_name"
						colorHex="#f9c728"
						[label]="playerData?.sport_option_position_name"
					></chip>
				</div>
			</div>
			<!-- likes -->
			<div class="foot mt-2 flex justify-between w-full">
				<div class="left flex gap-1 items-center">
					<img
						class="size-5"
						src="assets/icons/Icon ionic-ios-star.svg"
						alt=""
					/>
					<div class="num-rate text-white text-sm">
						{{ playerData?.likes || 0 }}
					</div>
				</div>
				<div StopClickPropagation class="mt-2 z-10">
					<div
						class="circle cursor-pointer w-4 h-4 rounded-full"
						[ngClass]="{
							'bg-textGreen': showDetails,
							'bg-darkGrey': !showDetails
						}"
						(click)="playerDetailsToggle()"
					></div>
					<div
						class="circl cursor-pointer w-4 h-4 rounded-full mt-2"
						[ngClass]="{
							'bg-textGreen': !showDetails,
							'bg-darkGrey': showDetails
						}"
						(click)="playerDetailsToggle()"
					></div>
				</div>
			</div>

			<div
				dir="auto"
				class="more-details absolute bg-darkBlue rounded-t-2xl w-full left-0 bottom-0"
			>
				<div class="name capitalize p-2 bg-lightBlue text-white rounded-t-2xl">
					{{
						playerData?.first_name +
							" " +
							playerData?.last_name[0] +
							"."
					}}
				</div>
				<div class="info mt-4 p-4 break-word whitespace-normal text-semiDarkGrey rounded-lg text-sm md:text-base">
					<!-- Details List -->
					<div class="grid grid-cols-2 gap-y-2">
						<!-- IDN -->
						<h3 class="text-slate-300">{{ "cart_player.IDN" | translate }}</h3>
						<h3 class="text-white">{{ playerData?.id }}</h3>
						<hr class="col-span-2 border-t border-gray-600">
				
						<!-- Height -->
						<h3 class="text-slate-300">{{ "cart_player.hight" | translate }}</h3>
						<h3 class="text-white">
							{{ playerData?.height }} {{ "cart_player.hight_value" | translate }}
						</h3>
						<hr class="col-span-2 border-t border-gray-600">
				
						<!-- Weight -->
						<h3 class="text-slate-300">{{ "cart_player.weight" | translate }}</h3>
						<h3 class="text-white">
							{{ playerData?.weight }} {{ "cart_player.weight_value" | translate }}
						</h3>
						<hr class="col-span-2 border-t border-gray-600">
				
						<!-- Status -->
						<h3 class="text-slate-300">{{ "cart_player.status" | translate }}</h3>
						<h3 class="text-white">
							{{
							playerData?.academy_id
							? ("cart_player.in_contract_player" | translate)
							: ("cart_player.free_player" | translate)
							}}
						</h3>
						<hr class="col-span-2 border-t border-gray-600">
				
						<!-- Club -->
						<h3 class="text-slate-300">{{ "cart_player.club" | translate }}</h3>
						<h3 class="text-white">
							{{
							playerData?.academy_id
							? playerData?.academy_name
							: playerData?.other_academy
							? playerData?.other_academy
							: ("account.account_management.account_info.withoutAcademyOrClub" | translate)
							}}
						</h3>
					</div>
				</div>

				<ul class="flex justify-evenly list-none p-2 pt-8 text-white">
					@if (userType && userType === permission.academy || userType
					=== permission.scouting && !hideFavInAccount &&
					showShortListButton) {
					<!-- <li
						StopClickPropagation
						class="w-10/12 flex justify-between p-2 rounded-lg items-center text-sm px-2"
						(click)="addToShortList()"
						[class.active]="playerData?.is_favorite"
					>
						<svg
							id="Icon_ionic-ios-heart"
							data-name="Icon ionic-ios-heart"
							xmlns="http://www.w3.org/2000/svg"
							width="17.553"
							height="16.877"
							viewBox="0 0 17.553 16.877"
						>
							<path
								id="Icon_ionic-ios-heart-2"
								data-name="Icon ionic-ios-heart"
								d="M16.2,3.937H16.16a4.8,4.8,0,0,0-4.008,2.194A4.8,4.8,0,0,0,8.143,3.937H8.1A4.77,4.77,0,0,0,3.375,8.705a10.271,10.271,0,0,0,2.017,5.6,35.344,35.344,0,0,0,6.759,6.51,35.344,35.344,0,0,0,6.759-6.51,10.271,10.271,0,0,0,2.017-5.6A4.77,4.77,0,0,0,16.2,3.937Z"
								transform="translate(-3.375 -3.937)"
								fill="#FFF"
							/>
						</svg>
						 <span>{{ "add_short_list" | translate }}</span> 
						@if(playerData.is_favorite === false &&
						this.playerData.hasOwnProperty('is_favorite')){
						<span>{{ "favorite.add_favorite" | translate }}</span>
						}@else {
						<span>{{
							"favorite.remove_favorite" | translate
						}}</span>
						}
					</li> -->
					} @if (userType && (userType === permission.player ||
					userType === permission.playground || userType ===
					permission.academy) || userType === permission.viwer &&
					!hideFavInAccount) {

					<li
						StopClickPropagation
						class="w-11/12 flex justify-evenly p-2 rounded-lg items-center text-sm px-2"
						(click)="addToFavourite()"
						[class.active]="playerData?.is_favorite"
					>
						<svg
							id="Icon_ionic-ios-heart"
							data-name="Icon ionic-ios-heart"
							xmlns="http://www.w3.org/2000/svg"
							width="17.553"
							height="16.877"
							viewBox="0 0 17.553 16.877"
						>
							<path
								id="Icon_ionic-ios-heart-2"
								data-name="Icon ionic-ios-heart"
								d="M16.2,3.937H16.16a4.8,4.8,0,0,0-4.008,2.194A4.8,4.8,0,0,0,8.143,3.937H8.1A4.77,4.77,0,0,0,3.375,8.705a10.271,10.271,0,0,0,2.017,5.6,35.344,35.344,0,0,0,6.759,6.51,35.344,35.344,0,0,0,6.759-6.51,10.271,10.271,0,0,0,2.017-5.6A4.77,4.77,0,0,0,16.2,3.937Z"
								transform="translate(-3.375 -3.937)"
								fill="#FFF"
							/>
						</svg>
						<!-- @if(playerData?.is_favorite) {
					<span>{{ "favorite.remove_favorite" | translate }}</span>
				 }
				 @else if (!playerData?.is_favorite) {
					<span>{{ "favorite.add_favorite" | translate }}</span>
				 } -->
						@if(playerData.is_favorite === false &&
						this.playerData.hasOwnProperty('is_favorite')){
						<span>{{ "favorite.add_favorite" | translate }}</span>
						}@else {
						<span>{{
							"favorite.remove_favorite" | translate
						}}</span>
						}
					</li>
					<!-- <li class="group w-10/12 flex justify-between p-2 rounded-lg items-center px-2 mt-3 text-sm"
				(click)="isComparsion=!isComparsion" [class.active]="isComparsion">
				<svg class="group-hover:hidden" id="Compair_icon" data-name="Compair icon"
					xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
					<path id="Icon_material-compare" data-name="Icon material-compare"
						d="M11.5,3.5h-5a2.006,2.006,0,0,0-2,2v14a2.006,2.006,0,0,0,2,2h5v2h2V1.5h-2Zm0,15h-5l5-6Zm9-15h-5v2h5v13l-5-6v9h5a2.006,2.006,0,0,0,2-2V5.5A2.006,2.006,0,0,0,20.5,3.5Z"
						transform="translate(-4.5 -1.5)" fill="#FFF" />
					<path id="Icon_material-compare-2" data-name="Icon material-compare"
						d="M15.5,3.5h5a2.006,2.006,0,0,1,2,2v14a2.006,2.006,0,0,1-2,2h-5v2h-2V1.5h2Zm0,15h5l-5-6Zm-9-15h5v2h-5v13l5-6v9h-5a2.006,2.006,0,0,1-2-2V5.5A2.006,2.006,0,0,1,6.5,3.5Z"
						transform="translate(-4.5 -1.5)" fill="#0569b1" opacity="0.002" />
				</svg>
				<svg class="hidden group-hover:flex" id="Compair_icon" data-name="Compair icon"
					xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
					<path id="Icon_material-compare" data-name="Icon material-compare"
						d="M11.5,3.5h-5a2.006,2.006,0,0,0-2,2v14a2.006,2.006,0,0,0,2,2h5v2h2V1.5h-2Zm0,15h-5l5-6Zm9-15h-5v2h5v13l-5-6v9h5a2.006,2.006,0,0,0,2-2V5.5A2.006,2.006,0,0,0,20.5,3.5Z"
						transform="translate(-4.5 -1.5)" fill="#d9d9d9" opacity="0" />
					<path id="Icon_material-compare-2" data-name="Icon material-compare"
						d="M15.5,3.5h5a2.006,2.006,0,0,1,2,2v14a2.006,2.006,0,0,1-2,2h-5v2h-2V1.5h2Zm0,15h5l-5-6Zm-9-15h5v2h-5v13l5-6v9h-5a2.006,2.006,0,0,1-2-2V5.5A2.006,2.006,0,0,1,6.5,3.5Z"
						transform="translate(-4.5 -1.5)" fill="#0569b1" />
				</svg>

				<span>{{ "comparison" | translate }}</span>
			</li> -->
					} @if (userType !== permission.viwer && userType !==
					permission.playground && userType !== permission.player &&
					showUnlock) {
					<li
						*ngIf="!userAuthenticationState"
						StopClickPropagation
						(click)="unlockProfile()"
						class="lock group w-10/12 flex justify-between p-2 rounded-lg items-center px-2 mt-3 text-sm"
						[class.active]="playerData?.is_unlocked"
					>
						<!-- LOCKED -->
						<!-- <svg
							[ngClass]="{ hidden: playerData?.is_unlocked }"
							id="Lock_icon"
							data-name="Lock icon"
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="22.1"
							viewBox="0 0 17 22.1"
						>
							<path
								id="Icon_ionic-ios-lock"
								data-name="Icon ionic-ios-lock"
								d="M21.625,11.025H20.35V8.544a5.1,5.1,0,1,0-10.2-.069v2.55H8.875A2.131,2.131,0,0,0,6.75,13.15v10.2a2.131,2.131,0,0,0,2.125,2.125h12.75A2.131,2.131,0,0,0,23.75,23.35V13.15A2.131,2.131,0,0,0,21.625,11.025Zm-5.631,6.63V21.31a.762.762,0,0,1-.707.765.745.745,0,0,1-.781-.744V17.655a1.7,1.7,0,1,1,1.488,0Zm2.869-6.63H11.637V8.475a3.613,3.613,0,0,1,7.225,0Z"
								transform="translate(-6.75 -3.374)"
								fill="#FFF"
							/>
							<path
								id="Icon_ionic-ios-unlock"
								data-name="Icon ionic-ios-unlock"
								d="M21.625,11.025H11.637V8.475a3.613,3.613,0,0,1,7.225,0h0a.744.744,0,0,0,1.488,0h0a5.1,5.1,0,1,0-10.2.069v2.481H8.875A2.131,2.131,0,0,0,6.75,13.15v10.2a2.131,2.131,0,0,0,2.125,2.125h12.75A2.131,2.131,0,0,0,23.75,23.35V13.15A2.131,2.131,0,0,0,21.625,11.025Zm-5.631,6.63V21.31a.762.762,0,0,1-.707.765.745.745,0,0,1-.781-.744V17.655a1.7,1.7,0,1,1,1.488,0Z"
								transform="translate(-6.75 -3.374)"
								fill="#FFF"
							/>
						</svg>

						
						<svg
							[ngClass]="{ hidden: !playerData?.is_unlocked }"
							id="Lock_icon"
							data-name="Lock icon"
							xmlns="http://www.w3.org/2000/svg"
							width="17"
							height="22.1"
							viewBox="0 0 17 22.1"
						>
							<path
								id="Icon_ionic-ios-lock"
								data-name="Icon ionic-ios-lock"
								d="M21.625,11.025H20.35V8.544a5.1,5.1,0,1,0-10.2-.069v2.55H8.875A2.131,2.131,0,0,0,6.75,13.15v10.2a2.131,2.131,0,0,0,2.125,2.125h12.75A2.131,2.131,0,0,0,23.75,23.35V13.15A2.131,2.131,0,0,0,21.625,11.025Zm-5.631,6.63V21.31a.762.762,0,0,1-.707.765.745.745,0,0,1-.781-.744V17.655a1.7,1.7,0,1,1,1.488,0Zm2.869-6.63H11.637V8.475a3.613,3.613,0,0,1,7.225,0Z"
								transform="translate(-6.75 -3.374)"
								fill="#d9d9d9"
								opacity="0"
							/>
							<path
								id="Icon_ionic-ios-unlock"
								data-name="Icon ionic-ios-unlock"
								d="M21.625,11.025H11.637V8.475a3.613,3.613,0,0,1,7.225,0h0a.744.744,0,0,0,1.488,0h0a5.1,5.1,0,1,0-10.2.069v2.481H8.875A2.131,2.131,0,0,0,6.75,13.15v10.2a2.131,2.131,0,0,0,2.125,2.125h12.75A2.131,2.131,0,0,0,23.75,23.35V13.15A2.131,2.131,0,0,0,21.625,11.025Zm-5.631,6.63V21.31a.762.762,0,0,1-.707.765.745.745,0,0,1-.781-.744V17.655a1.7,1.7,0,1,1,1.488,0Z"
								transform="translate(-6.75 -3.374)"
								fill="#d6303b"
							/>
						</svg> -->

						<!-- Favorite -->
						<svg
							id="Icon_ionic-ios-heart"
							data-name="Icon ionic-ios-heart"
							xmlns="http://www.w3.org/2000/svg"
							width="17.553"
							height="16.877"
							viewBox="0 0 17.553 16.877"
						>
							<path
								id="Icon_ionic-ios-heart-2"
								data-name="Icon ionic-ios-heart"
								d="M16.2,3.937H16.16a4.8,4.8,0,0,0-4.008,2.194A4.8,4.8,0,0,0,8.143,3.937H8.1A4.77,4.77,0,0,0,3.375,8.705a10.271,10.271,0,0,0,2.017,5.6,35.344,35.344,0,0,0,6.759,6.51,35.344,35.344,0,0,0,6.759-6.51,10.271,10.271,0,0,0,2.017-5.6A4.77,4.77,0,0,0,16.2,3.937Z"
								transform="translate(-3.375 -3.937)"
								fill="#FFF"
							/>
						</svg>
						<span>{{ "favorite.add_favorite" | translate }}</span>
					</li>
					}
				</ul>
			</div>
		</div>
	</span>
</cart>

@if (playerData?.id) {
<app-share-modal
	[visible]="showShare"
	[urlToShare]="currentWebsiteUrl + '/main/players/' + playerData.slug"
	(OnHide)="showShare = $event"
></app-share-modal>
}

<svg style="display: none" xmlns="http://www.w3.org/2000/svg">
	<symbol id="icon-like-svgrepo-com" viewBox="0 0 32 32">
		<path
			fill="#5f6379"
			style="fill: var(--color8, #5f6379)"
			d="M6 29.334h-3.253c-1.512 0-2.747-1.234-2.747-2.75v-14.503c0-1.516 1.234-2.747 2.747-2.747h3.919c0.362 0 0.709 0.147 0.963 0.409 0.25 0.262 0.384 0.613 0.372 0.975l-0.666 17.334c-0.028 0.712-0.619 1.281-1.334 1.281zM2.747 12c-0.044 0-0.081 0.037-0.081 0.081v14.503c0 0.044 0.038 0.081 0.081 0.081h1.969l0.566-14.666h-2.534z"
		></path>
		<path
			fill="#3688ff"
			style="fill: var(--color9, #3688ff)"
			d="M25.325 29.334h-16.709l0.769-20h3.678l2.25-6.306c0.647-1.813 2.375-3.028 4.297-3.028 1.325 0 2.581 0.575 3.447 1.575s1.256 2.325 1.069 3.634l-0.591 4.125h4.456c2.209 0 4.006 1.797 4.006 4.006l-0.031 0.291-2.637 11.856c-0.081 2.134-1.847 3.847-4.003 3.847zM11.384 26.666h13.941c0.738 0 1.341-0.6 1.341-1.341l0.031-0.291 2.631-11.816c-0.063-0.681-0.637-1.219-1.334-1.219h-7.531l1.025-7.169c0.078-0.553-0.078-1.087-0.444-1.509s-0.875-0.653-1.434-0.653c-0.8 0-1.516 0.506-1.784 1.259l-2.884 8.072h-2.991l-0.566 14.666z"
		></path>
	</symbol>
</svg>
