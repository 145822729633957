import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PaymentWays } from '@sportyano/core/models/subscription/Payment-ways';
import { Plans } from '@sportyano/core/models/subscription/Plan';
import { UserType } from '@sportyano/core/models/user-type/user-type';
import { PaymentMethodProvidersType, PaymentMethodsType } from '@sportyano/main-site/booking/booking/models/const/booking.const';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
private _httpClient :HttpClient = inject(HttpClient)

// get plans of subscriptions
  public getPlans(userType:UserType):Observable <Plans[]> {
    return this._httpClient.get<Plans[]>(`${environment.baseUrl}${userType}/plans`);
  }

// subscribe to plan NOT FREE(BASIC / PRO /PERINEUM )
  public subscribeToPlan(userType:UserType,plan_id:number,paymentWay:PaymentWays):Observable<any>{
    return this._httpClient.post<any>(`${environment.baseUrl}${userType}/subscription`,{plan_id,...paymentWay})
  }
  // subscribe to plan (FREE plan)
  public subscribeToFreePlan (userType:UserType,plan_id:number,payment_method:number):Observable<any>{
    return this._httpClient.post<any>(`${environment.baseUrl}${userType}/subscription`,{plan_id,payment_method})
  }
  // get payment ways providers
  public getOnlinePaymentWay():Observable<any>{
		return this._httpClient.get<any>(`${environment.baseUrl}get_way`)
	}


}
