<div class="location-card-container rounded-md bg-semiLightGray flex flex-col h-full p-2">
	<!-- Map Section -->
	<div class="map-container hidden lg:block">
		<div class="map-frame">
			<div id="map"></div>
		</div>
	</div>

	<!-- Address -->
	<div class="grid grid-cols-1 justify-center p-1 lg:p-2 text-center">
		<p class="text-sm text-fontColor break-words max-w-full">
			{{ locationData.address }}
		</p>
	</div>

	<!-- Get Directions Button -->
	<div class="details-container p-1 lg:p-2">
		<a href="{{ locationData.location_url }}" target="_blank"
			[ngClass]="{'pointer-events-none opacity-50': !locationData.location_url}"
			class="btn-get-direction bg-semiLightGray border border-fontColor text-base text-fontColor rounded-md shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor flex items-center justify-center">
			{{ "SPORT_ACADEMIES.Get_Direction" | translate }}
		</a>
	</div>

	<!-- Contact Info -->
	<div class="text-sm text-fontColor text-center p-1 lg:p-2">
		{{ "contact.customerService" | translate }}
		<a href="tel:01101003140" class="tel">01101003140</a>
	</div>
</div>