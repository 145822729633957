<div class="flex flex-col gap-3 py-6 px-4">
	<!--Plans-->
	<div class="relative my-86">
		<div class="overflow-x-auto py-5">
			<div class="plan-wrapper">
				@for (plan of subscriptionPlans; track plan.id) {
				<label class="plan-item cursor-pointer" [ngClass]="{
						'plan-item-in-account-route': isAccountRoute(),
						'plan-item': !isAccountRoute(),
						 'selected': selectedPlan == plan.id ,
					}" for="{{ plan.id }}">
					<div class="plan-header">
						<label class="text-2xl cursor-pointer" for="{{ plan.id }}">{{ plan.name }}</label>
						<p class="text-white text-2xl">
							{{ plan.price | currency :'EGP':'symbol-narrow' }}
						</p>
					</div>

					<div class="plan-body">
						<ul class="flex flex-col">
							<li *ngFor="
									let item of plan.features.slice(
										0, showMoreMap() ? plan.features.length : 6
									);
									let i = index
								">
								<div class="flex justify-start ">
									<div>
										<span class="flex items-center" >
											<icon [name]="
													item.status
														? ICONS.check
														: ICONS.notSelected_check
												"></icon>
										</span>
									</div>
									<div>
										<span *ngIf="item.charges !== null"
											class="text-base underline underline-offset-2 cursor-pointer text-fontColor text-center font-extrabold">
											{{
											item.charges!
											| convertdecimaltonumber
											}}
										</span>
										<span class="text-sm text-fontColor text-center" [ngClass]="{
												'line-through':
												!item.status,
												'text-slate-500':!item.status}"
												 [pTooltip]="item.description"
												>

											{{ item.title }}
										</span>
									</div>
								</div>
							</li>
						</ul>
						<button *ngIf="plan.features.length > 6" (click)="toggleShowMore()"
							class="text-textGreen text-sm">
							{{
							showMoreMap()
							? ("subscriptions.showLessFeatures" | translate)
							: ("subscriptions.showMoreFeatures" | translate)
							}}
							<i [ngClass]="
									showMoreMap()
										? 'pi pi-arrow-circle-up'
										: 'pi pi-arrow-circle-down'
								" style="font-size: 1rem"></i>
						</button>
						@if (selectedPlan == plan.id) {
							 @if (subscribedPlan() === plan.id) {
							<button
								class="transition-colors border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md px-4 py-1 text-center">
								{{ "subscription.your_plan" | translate }}
							</button>
							} @else {
							<button (click)="
									plan.price > 0
										? subscribe(plan, plan.id)
										: subscribeForFreePlan(plan,plan.id)
								" class="transition-colors border border-red-600 hover:text-red-600 bg-red-600 hover:bg-semiDarkBlue text-white rounded-md px-4 py-1 text-center">
								{{ "subscription.subscribe" | translate }}
							</button>
						}
						}
						@if (selectedPlan == plan.id && plan.name === 'Free plan' && hiddenInAccount) {
						<button routerLink="/auth/login"
							class="transition-colors border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md px-4 py-1 text-center">
							{{ "subscription.continue" | translate }}
						</button>
						}
					</div>
					<input type="radio" name="plan" [value]="plan.id" id="{{ plan.id }}" [(ngModel)]="selectedPlan"
						class="hidden w-0 h-0" />
				</label>
				}
			</div>
		</div>
	</div>
</div>

<!-- <p-confirmDialog #cd>
	<ng-template pTemplate="headless" let-message>
		<div class="flex flex-col items-center p-5 bg-white rounded-lg shadow-lg">
			<img src="assets/images/image/confetti.svg">

			<span class="font-bold text-2xl text-lightBlue block mb-2 mt-4">
				{{ "subscriptions.congratulations" | translate }}
			</span>
			<p class="mb-2 text-black text-center" [innerHTML]="message.message"></p>
			<div class="flex items-center gap-2 mt-4">
				<button pButton 	[label]="'subscriptions.close' | translate" (click)="cd.reject()"
					class="border bg-lightBlue text-white py-2 px-4 rounded-lg"></button>
			</div>
		</div>
	</ng-template>
</p-confirmDialog> -->
<ng-template #tooltipContent let-description="description">
    <div class="flex align-items-center">
        <span> {{description}}</span>

    </div>
</ng-template>
