export interface FileUploadResponse {
	status: string,
	path: string
}

export interface IUploadedFile {
	src: string;
	type: any
}

export const FILE_TYPE = {
	video: 'video',
	image: 'image'
}

  export type FileType = (typeof FILE_TYPE)[keyof typeof FILE_TYPE];
  
